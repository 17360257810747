import React from 'react';
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";

function Developmentprocess({data}) {
    if (data && data.development_process_title) {
        return (
            <section className="developmemt_proces p-100">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                            <div className="example" data-text={data?.development_process_body_txt}>
                                <h2>{checkData(data, 'development_process_title')}</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="my-auto">
                            <Tabs defaultActiveKey="0" transition={false}>
                                {data?.development_process_tabs?.map((value, i) => (
                                    <Tab eventKey={i} title={value.title} key={i}>
                                        <div className="content_tabs">
                                            <div className="tabs_txt">
                                                <h4>{value.title}</h4>
                                                <div className="tabscontent"
                                                     dangerouslySetInnerHTML={{__html: value.description}}/>
                                            </div>
                                            <div className="tabsimg">
                                                <img
                                                    src={`${mediaBaseURL}${checkData(value, 'image')}`}
                                                    className="img-fluid"
                                                    alt={checkData(value, 'title')}/>
                                            </div>
                                        </div>
                                    </Tab>
                                ))}

                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    } else {
        return ('');

    }

}

export default Developmentprocess;